import './index.css';
import { Routes, Route } from "react-router-dom";
import Webpage from './components/Webpage';

function App() {
  return (
      <div>
      <Routes>
      <Route path="/" element={<Webpage/>}/>
      </Routes>
    </div>
  );
}

export default App;
